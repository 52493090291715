// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiCore: 'https://apiv2.dev.icheck.vn',
  apiCoreV2: 'https://apiv2.dev.icheck.vn',
  // apiLoyalty: 'https://api.dev.icheck.vn/api/business/loyalty/cms',
  // apiPublicLoyalty: 'https://api.dev.icheck.vn/api/business/public/loyalty',
  // apiNotPublicLoyalty: 'https://api.dev.icheck.vn/api/business/loyalty',
  apiLoyalty: 'https://apiv2.dev.icheck.vn/api/business/loyalty/cms',
  apiPublicLoyalty: 'https://api-loyalty.dev.icheck.vn/api/business/public/loyalty',
  apiNotPublicLoyalty: 'https://api-loyalty.dev.icheck.vn/api/business/public/loyalty',
  urlImage: 'https://upload.icheck.vn/hi/',
  url_static_image: 'http://icheckcdn.net/images/720x720',
  url_mkt: 'http://cd-dev.qcheck.vn/',
  openAppiCheck: 'https://icheckdev.page.link/Home',
  openAppiCheckReward: 'icheck://my_rewards',
  urlWebapp: 'https://webapp-loyalty.dev.icheck.vn/',
  isVersionViettel: false,
  phongvu: {
    isEnable: true,
    userIds: [71],
    landingUrl: 'https://phongvu-landing.dev.icheck.vn'
  },
  // urlWebapp: 'http://localhost:8100/'
  // urlWebapp: 'http://10.10.1.105:8100/'
  // urlWebapp: 'http://10.10.1.220:4444/'
  tracking_id_web: '5eac7a04-7565-4768-9d9c-b43293f77250',
  tracking_id_mobile: 'ddebc619-4f17-4a77-b091-8d99ed64b7b9',
  behnmeyer: {
    userId: 241,
    urlWebapp: 'https://loyalty-webapp-behnmeyer.dev.icheck.vn/'
  },
  userIcheck: {userId: [364]},
  training_customer: {
    userId: 241,
    minhchau: 271
  },
  LOrealId: 184,
  firebaseConfig: {
    // apiKey: 'AIzaSyBoy498L5e0dioxK8Smrch0smNT90eWqNg',
    // authDomain: 'icheck-trace.firebaseapp.com',
    // databaseURL: 'https://icheck-trace-default-rtdb.asia-southeast1.firebasedatabase.app',
    // projectId: 'icheck-trace',
    // storageBucket: 'icheck-trace.appspot.com',
    // messagingSenderId: '531819361870',
    // appId: '1:531819361870:web:943742c0b2bebd69c1b51f',
    // measurementId: 'G-P5W7X0LPRH'
    apiKey: 'AIzaSyBqaN-tvF5wRkxx-W7JFlwZX6_v2OhzpBo',
    authDomain: 'test-2beee.firebaseapp.com',
    projectId: 'test-2beee',
    storageBucket: 'test-2beee.appspot.com',
    messagingSenderId: '136375662802',
    appId: '1:136375662802:web:21e92f0cce08c8923e150b',
    measurementId: 'G-7BHCEKC10K'
  },
  userIdCampaignAll: 1,
  googleAnalyticId: 'G-F33EG7WB70'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


